<template>
  <span>
    <!-- App Bar -->
    <v-app-bar
      app
      fade-img-on-scroll
      dark
      :src="require('../assets/bg.jpg')"
      color="#225590"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          position="center center"
          gradient="to top right, rgba(34,85,144,.9), rgba(34,85,144,.7)"
          alt="gb"
        ></v-img>
      </template>

      <v-layout>
        <v-flex style="font-size: 2em">My DoCSoc</v-flex>
        <SignOut></SignOut>
      </v-layout>
    </v-app-bar>

    <v-main>
      <!-- Subpage -->
      <v-container fluid class="pb-10">
        <router-view></router-view>
      </v-container>

      <!-- Navigation -->
      <v-bottom-navigation shift fixed v-model="activePage">
        <v-btn
          v-for="page in pages"
          :key="page.name"
          :value="page.path"
          :to="page.path"
        >
          <span>{{ page.name }}</span>
          <v-icon>{{ page.icon }}</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-main>
  </span>
</template>

<script>
import SignOut from "../components/SignOut";

export default {
  fiery: true,
  data() {
    return {
      activePage: window.location.pathname,
      pages: [
        {
          path: "/admin/events",
          name: "Events",
          icon: "mdi-calendar",
        },
        {
          path: "/admin/score",
          name: "Ranking",
          icon: "mdi-clipboard-text-outline",
        },
        {
          path: "/admin/scan",
          name: "Scan",
          icon: "mdi-qrcode",
        },
        {
          path: "/admin/news",
          name: "News",
          icon: "mdi-newspaper",
        },
      ],
    };
  },
  components: { SignOut },
};
</script>
